import '../../styles/pages/fall-winter-2023/presidents-message.scss';
import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
// import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/fall-winter-2023-stories/more-stories"

import socialBanner from "../../images/fall-winter-2023/social-banners/presidents-message.jpg";
import Alva from "../../images/fall-winter-2023/presidents-message/alva.jpg";


export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    var pageTitle = 'President’s Message';
    var pageDesc = 'It is a tremendous honor to return to Cal State Fullerton where I began my career. This university is where I learned what it meant to be an educator. What it meant to be a champion for student success, equity and inclusivity. What it meant to be a Titan!';
    var slug = 'presidents-message';

    return (
      <Layout headerStyle="black" locationInfo={this.props.location} issue="fall-winter-2023">
        <Helmet>
          <title>{pageTitle}</title>
          <body className={`fall-winter-2023 ${slug}`} />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        <article className="wrap small" style={{ marginTop: '6em' }}>

          <figure className='align-left align-outside'>
            <img src={Alva} alt='President Sylvia A. Alva' />
          </figure>

          <h1>{pageTitle}</h1>

          <p><span className='intro-text'>It is a tremendous honor to return to Cal State Fullerton</span> where I began my career. This university is where I learned what it meant to be an educator. What it meant to be a champion for student success, equity and inclusivity. What it meant to be a Titan! </p>

          <p>Reflecting on my journey, my observations keep bringing me back to a common theme, and that theme is opportunity.</p>

          <p>I am grateful for the privilege to return as president and pay forward the valuable opportunities that my CSU education and career afforded me.</p>

          <p>Built on the foundation of inclusive excellence, a Cal State Fullerton education sets students on their course to make a positive social and economic impact in the world. According to the 2024 U.S. News & World Report, we are No. 2 in the nation for social mobility. This is not by accident. At Cal State Fullerton, we are committed to the promise of education, and we pride ourselves on helping our students achieve their goals.</p>

          <p>Storytelling is often the best way to show where seeds of opportunity are planted and how a Cal State Fullerton education leads to exceptional contributions by our students and alumni. In this issue of Titan magazine, we learn how valuable university experiences can lead to vibrant careers in entertainment, sports journalism, finance, sustainability and ending hunger.</p>

          <p>We tell the story of alumnus Omid Abtahi ’02 (B.A. communications-advertising) and how he credits the Cal State Fullerton theatre arts program for his success. Among his many television and film roles, he made his mark in the “Star Wars” franchise as Dr. Penn Pershing in the first three seasons of “The Mandalorian” on Disney+.</p>

          <p>Alumnus Jeffrey Van Harte ’80 (B.A. business administration-finance) chose to pay it forward by establishing the award-winning Titan Capital Management. Since 2016, the program has provided students the opportunity to manage real-world investment portfolios while also gaining leadership, teamwork, and oral and written communication skills.</p>

          <p>In this issue of Titan magazine we learn how Titan Athletics partnered with the College of Communications to create Titans Sports Network. This student-run program makes it possible for students to produce sports media broadcasts for ESPN+. We also have Titans finding sustainable solutions to ending hunger. Our team at the Fullerton Arboretum and the Campus Garden are collaborating with Associated Students Inc.’s Food Pantry to provide fresh produce for students facing food insecurity.</p>

          <p>The opportunity to make a positive and lasting impact on our world starts in a place of possibility. That place is Cal State Fullerton.</p>

          <p>I hope you enjoy these stories and more in this issue of Titan magazine. Go Titans!</p>

          <p style={{lineHeight: '1.2em'}}>
            Sincerely,<br /><br />
            {/* <img src={framSignature} alt="Fram Virjee" width="200px" /><br /> */}
            Sylvia A. Alva, Ph.D.<br />
            President<br />
            California State University, Fullerton
          </p>

        </article>
        <MoreStories slug={slug} />
      </Layout>
    );
  }
} 